import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import useGlobalStore, { GatheringPoint } from "../../store/useGlobalStore";
import moment from "moment";
import { API } from "../../httpClient/httpClient";
import config from "../../constants/config";

interface GatheringPointModalProps {
  item: GatheringPoint | null;
  onHide: () => void;
}

type User = {
  full_name: string;
  user_id: number;
  avatar: string;
};

interface Data {
  id: number;
  address: string;
  latitude: number;
  longitude: number;
  scheduled_time: string; // ISO 8601 datetime string
  attended_users: User[];
  unattended_users: User[];
  militia: number;
  responsible_full_name: string;
  responsible_phone: string;
  completed: boolean;
}

export const GatheringPointModal: React.FC<GatheringPointModalProps> = ({ item, onHide }) => {
  const [gatheringData, setGatheringData] = React.useState<Data | null>(null);
  const { druzhiny } = useGlobalStore();
  const getGatheringData = async () => {
    const response = await API.get<Data>({ endPoint: `/gathering-points/${item?.id}/` });
    if (response.status === 200) {
      console.log("response.data", response.data);
      setGatheringData(response.data);
    }
  };

  const findMilitia = druzhiny.find((el) => el.commander_name === item?.commander);

  useEffect(() => {
    if (item) {
      getGatheringData();
    }
  }, [item]);

  return (
    <Dialog header={`Точка сбора дружины "${item?.militia_name}"`} visible={!!item} onHide={onHide} style={{ width: "450px" }} modal className="p-fluid">
      <div className="p-field mt-1.5">
        <span>
          Командир дружины:{" "}
          <a href={`${config.API_URL}/admin/users/user/${findMilitia?.commander}/`} target="_blank" className="text-primary">
            {item?.commander}
          </a>
        </span>
      </div>
      <div className="p-field mt-1.5">
        <span>Адрес: {item?.address}</span>
      </div>
      <div className="p-field mt-1.5">
        <span>Начало: {moment(item?.scheduled_time).format("HH:mm / DD.MM.YYYY")}</span>
      </div>
      <div className="p-field mt-1.5">
        <div>
          <p className="font-semibold">Явились:</p>
          {gatheringData?.attended_users.length ? (
            gatheringData.attended_users.map((user) => (
              <a href={`${config.API_URL}/admin/users/user/${user.user_id}/`} target="_blank" className="items-center flex mt-1 text-primary" key={user.user_id}>
                <img
                  src={user.avatar ? user.avatar : require("../../assets/markers/avatar.png")}
                  alt={user.full_name}
                  className="w-8 h-8 rounded-full border-2 border-blue-600"
                />
                <span className="ml-2">{user.full_name}</span>
              </a>
            ))
          ) : (
            <p className="-mt-3">Пользователи не найдены</p>
          )}

          <p className="font-semibold">Не явились:</p>
          {gatheringData?.unattended_users.length ? (
            gatheringData.unattended_users.map((user) => (
              <a href={`${config.API_URL}/admin/users/user/${user.user_id}/`} target="_blank" className="items-center flex text-primary mt-1" key={user.user_id}>
                <img
                  src={user.avatar ? user.avatar : require("../../assets/markers/avatar.png")}
                  alt={user.full_name}
                  className="w-8 h-8 rounded-full border-2 border-blue-600"
                />
                <span className="ml-2">{user.full_name}</span>
              </a>
            ))
          ) : (
            <p className="-mt-3">Пользователи не найдены</p>
          )}
        </div>
      </div>
    </Dialog>
  );
};
