import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IGetMulitiasRequest, IMilitia } from "../../httpClient/dto/requests/GetMulitiasRequest";
import config from "../../constants/config";
import { createTelegramLink } from "../../utils";

interface SquadModalProps {
  item: IGetMulitiasRequest | null;
  onHide: () => void;
}

export const SquadModal: React.FC<SquadModalProps> = ({ onHide, item }) => {
  return (
    <Dialog header={`Дружина "${item?.name}"`} visible={item ? true : false} onHide={onHide} style={{ width: "450px" }} modal className="p-fluid">
      <div className="p-field m-1.5">
        <span>
          Командир дружины:{" "}
          <a href={`${config.API_URL}/admin/users/user/${item?.commander}/`} target="_blank" className="text-primary">
            {item?.commander_name}
          </a>
        </span>
      </div>
      <div className="p-field m-1.5">
        <span>Адрес: {item?.address}</span>
      </div>
      <div className="p-field m-1.5">
        <span>
          Количество участников: {item?.participants.length} / {item?.participant_limit}
        </span>
      </div>
      <div className="p-field mt-7">
        <Button
          onClick={() => {
            if (item?.commander_phone) {
              window.open(createTelegramLink(item.commander_phone), "_blank");
            }
          }}
          label="Связаться с командиром"
          style={{ width: "100%" }}
        />
        <Button
          onClick={() => {
            window.open(`${config.API_URL}/admin/militia/militia/${item?.id}/`, "_blank");
          }}
          label="Перейти в дружину"
          style={{ width: "100%", marginTop: "10px" }}
        />
      </div>
    </Dialog>
  );
};
