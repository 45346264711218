import React, { useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import useGlobalStore from "../store/useGlobalStore";

export const SelectDruzhinaComponent: React.FC = () => {
  const { selectedDruzhina, setDruzhina, druzhiny } = useGlobalStore();

  const handleDruzhinaChange = (e: { value: number }) => {
    setDruzhina(e.value);
  };

  return (
    <Dropdown
      value={selectedDruzhina}
      options={druzhiny.map((d) => ({ label: d.name, value: d.id }))}
      onChange={handleDruzhinaChange}
      showClear
      placeholder="Выберите дружину"
      panelClassName="w-80"
      className="w-80"
      filter
    />
  );
};
