import "./App.css";
import { Panel } from "primereact/panel";
import { DatePickerComponent } from "./components/DatePickerComponent";
import { TabMenuComponent } from "./components/TabMenuComponent";
import { FioInputComponent } from "./components/FioInputComponent";
import { SelectDruzhinaComponent } from "./components/SelectDruzhinaComponent";
import { MapComponent } from "./components/MapComponent";
import useGlobalStore from "./store/useGlobalStore";
import { useEffect, useRef } from "react";
import { Message } from "primereact/message";

// Константа для интервала в 30 секунд
const REFRESH_INTERVAL = 30000;

function App() {
  const { getMilitiasRequest, date, selectedDruzhina, selectedUser, getNewDataRequest, mode, getRoutesRequest } = useGlobalStore();

  // Присваиваем правильный тип для ref, который может быть либо Node.js таймером, либо браузерным числом
  const intervalRef = useRef<number | NodeJS.Timeout | null>(null);

  // Функция для установки правильного интервала в зависимости от режима
  const setupInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }

    if (mode === "Стандарт") {
      getNewDataRequest(); // Выполнить начальный вызов
      intervalRef.current = setInterval(getNewDataRequest, REFRESH_INTERVAL);
    } else {
      getRoutesRequest(); // Выполнить начальный вызов
      intervalRef.current = setInterval(getRoutesRequest, REFRESH_INTERVAL);
    }
  };

  // Эффект для инициализации данных при первом рендере
  useEffect(() => {
    getMilitiasRequest();
  }, []);

  // Эффект для переключения между режимами и установки таймера
  useEffect(() => {
    setupInterval();

    // Очистка интервала при размонтировании компонента или смене режима
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
      }
    };
  }, [mode, date, selectedDruzhina, selectedUser]);

  // Логика отображения ошибки
  const isVisibleError = (mode !== "Стандарт" && !date) || (mode !== "Стандарт" && !selectedUser);

  return (
    <>
      {isVisibleError && <Message className="w-full" severity="error" text="Чтобы построить маршрут на карте, пожалуйста выберите дату и пользователя" />}

      <Panel className="h-full" header="Админ панель">
        <div className="flex justify-between">
          <DatePickerComponent />

          <div className="flex">
            <SelectDruzhinaComponent />
            <div className="ml-1">
              <FioInputComponent />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <TabMenuComponent />

          <div className="mt-4">
            <MapComponent />
          </div>
        </div>
      </Panel>
    </>
  );
}

export default App;
