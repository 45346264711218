import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import useGlobalStore, { Person } from "../../store/useGlobalStore";
import config from "../../constants/config";
import { createTelegramLink } from "../../utils";

interface MemberModalProps {
  item: Person | null;
  onHide: () => void;
}

export const MemberModal: React.FC<MemberModalProps> = ({ item, onHide }) => {
  const { setMode, setSelectedUser, druzhiny } = useGlobalStore();
  return (
    <Dialog header={item?.full_name} visible={item ? true : false} onHide={onHide} style={{ width: "450px" }} modal className="p-fluid">
      <div className="p-field mb-8">
        <span>
          Роль:{" "}
          <a href="#" className="text-primary">
            {item?.role === "VIGILANTE" ? "Дружинник" : "Командир"}
          </a>
        </span>
      </div>
      <div className="p-field">
        <Button
          onClick={() => {
            setSelectedUser(item?.id ?? 0);
            setMode("Маршруты");
            onHide();
            // setMode('')
          }}
          label="Просмотреть историю передвижений"
          style={{ width: "100%" }}
        />
        <Button
          onClick={() => {
            if (item !== null) {
              const findMilitia = druzhiny.find((element) => element.id === item.militia_id);
              window.open(`${config.API_URL}/admin/militia/militia/${findMilitia?.id || item.militia_id}/`, "_blank");
            }
          }}
          label="Перейти в дружину"
          style={{ width: "100%", marginTop: "10px" }}
        />
        {/* <Button label="Назначить задачу" style={{ width: "100%", marginTop: "10px" }} /> */}
        <Button
          onClick={() => {
            if (item?.id) {
              window.open(`${config.API_URL}/admin/users/user/${item.id}/`, "_blank");
            }
          }}
          label="Перейти в пользователя"
          style={{ width: "100%", marginTop: "10px" }}
        />
        <Button
          onClick={() => {
            if (item?.phone) {
              window.open(createTelegramLink(item.phone), "_blank");
            }
          }}
          label="Связаться"
          style={{ width: "100%", marginTop: "10px" }}
        />
      </div>
    </Dialog>
  );
};
