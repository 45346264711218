import React, { useContext, useMemo } from "react";
import { InputText } from "primereact/inputtext";
import useGlobalStore from "../store/useGlobalStore";
import { Dropdown } from "primereact/dropdown";

export const FioInputComponent: React.FC = () => {
  const { selectedDruzhina, selectedUser, druzhiny, allUsers, setSelectedUser, mode } = useGlobalStore();

  const usersOptions = useMemo(() => {
    if (selectedDruzhina) {
      const findDruzhina = druzhiny.find((item) => item.id === selectedDruzhina);
      if (findDruzhina) {
        let users = findDruzhina?.participants.map((item) => ({ label: item.user_name, value: item.user_id }));
        if (mode === "Маршруты") {
          return [{ label: "Все", value: "Все" }, ...users];
        }
        return users;
      }
    } else {
      return allUsers;
    }
  }, [selectedDruzhina, druzhiny, allUsers, mode]);

  const handleFioChange = (e: { value: number }) => {
    setSelectedUser(e.value);
  };

  return (
    <div className="p-field">
      <Dropdown
        filter
        value={selectedUser}
        showClear
        options={usersOptions}
        onChange={handleFioChange}
        placeholder="Выберите ФИО"
        panelClassName="w-80"
        className="w-80"
      />
    </div>
  );
};
