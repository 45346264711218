import { create } from "zustand";
import { API } from "../httpClient/httpClient";
import { IGetMulitiasRequest, IMilitia } from "../httpClient/dto/requests/GetMulitiasRequest";
import moment from "moment";

// Интерфейс для локации
interface Location {
  lat: number;
  lon: number;
}

// Интерфейс для смены и маршрута
interface RoutePoint {
  lat: number;
  lon: number;
}

// Интерфейс для гео-данных
interface GeoInfo {
  location: Location;
  timestamp: string;
  on_car: boolean;
}

interface RouteUser {
  avatar: string;
  email: string;
  full_name: string;
  id: number;
  phone: string;
}

interface Shift {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
  route: RoutePoint[];
  user_militia: number;
}

// Интерфейс для SOS сигнала
interface SosSignal {
  is_active: boolean;
  created_at: string;
}

// Интерфейс для человека
export interface Person {
  id: number;
  full_name: string;
  phone: string;
  militia_id: number;
  role: "VIGILANTE" | string; // Можно добавить дополнительные роли, если они есть
  last_geo: GeoInfo | null;
  active_sos_signal: SosSignal | null;
  avatar: string | null;
}

// Интерфейс для точки сбора
export interface GatheringPoint {
  id: number;
  location: Location;
  scheduled_time: string;
  completed: boolean;
  address: string | null;
  militia_name: string;
  commander: string;
}

// Интерфейс для задачи
export interface Task {
  id: number;
  name: string;
  description: string;
  status: "pending" | "completed";
  completed_at: string | null;
  creator: number;
  executor: number;
  creator_name: string;
  executor_name: string;
  location: Location;
}

// Интерфейс для отчетов
export interface Report {
  id: number;
  user: number;
  phone: string;
  description: string;
  location: Location;
  created_at: string;
  user_name: string;
  user_id: number;
}

// Интерфейс для данных ответа от API
interface ApiResponseData {
  people: Person[];
  gathering_points: GatheringPoint[];
  tasks: Task[];
  reports: Report[];
}

// Интерфейс для данных ответа от API маршрутов
interface RoutesApiResponse {
  shifts: Shift[];
  tasks: Task[];
  reports: Report[];
  user: RouteUser;
}

// Интерфейсы для состояния и действий
export interface FiltersState {
  closedTasks: boolean;
  openTasks: boolean;
  comanderIncidents: boolean;
  telegramIncidents: boolean;
  volunteers: boolean;
  commanders: boolean;
  squadHeadquarters: boolean;
  sosSignals: boolean;
  collectionPoints: boolean;
}

export interface IUsers {
  label: string;
  value: number | null;
}

interface CurrentShiftRoute {
  shifts: Shift[];
  user: RouteUser | null;
}

export interface GlobalState {
  date: Date | null;
  mode: "Стандарт" | "Маршруты";
  druzhiny: IGetMulitiasRequest[];
  selectedDruzhina: number | null;
  selectedUser: number | null | string;
  filters: FiltersState;
  allUsers: IUsers[];
  modalDruzhina: IGetMulitiasRequest | null;
  modalTask: Task | null;
  modalPerson: Person | null; // Модалка для пользователя
  modalGatheringPoint: GatheringPoint | null; // Модалка для точки сбора
  modalReport: Report | null; // Модалка для отчета
  // Новые данные
  newPeopleData: Person[];
  newGatheringPoints: GatheringPoint[];
  newTasks: Task[];
  newReports: Report[];
  // Route
  currentShift: CurrentShiftRoute[]; // Добавляем поле для хранения данных о смене
}

// Интерфейс для объединенного состояния и действий
type GlobalStore = GlobalState & {
  setDate: (dates: Date | null) => void;
  setMode: (mode: "Стандарт" | "Маршруты") => void;
  setDruzhina: (druzhina: number | null) => void;
  setSelectedUser: (selectedUser: number | null) => void;
  setFilters: (filters: FiltersState) => void;
  toggleFilter: (filter: keyof FiltersState) => void;
  getMilitiasRequest: () => void;
  getNewDataRequest: () => Promise<void>;
  getRoutesRequest: () => Promise<void>; // Новый метод для запроса маршрутов
  // Modals
  setDruzhinaModal: (data: IGetMulitiasRequest | null) => void;
  setTaskModal: (data: Task | null) => void;
  setPersonModal: (data: Person | null) => void;
  setGatheringPointModal: (data: GatheringPoint | null) => void;
  setReportModal: (data: Report | null) => void;
};

// Создаем zustand store с типизацией
const useGlobalStore = create<GlobalStore>((set, get) => ({
  date: null,
  mode: "Стандарт",
  druzhiny: [],
  allUsers: [],
  selectedDruzhina: null,
  selectedUser: null,

  filters: {
    closedTasks: true,
    openTasks: true,
    comanderIncidents: true,
    telegramIncidents: true,
    volunteers: true,
    commanders: true,
    squadHeadquarters: true,
    sosSignals: true,
    collectionPoints: true,
  },
  modalDruzhina: null,
  modalTask: null,
  modalPerson: null,
  modalGatheringPoint: null,
  modalReport: null,
  newPeopleData: [],
  newGatheringPoints: [],
  newTasks: [],
  newReports: [],
  currentShift: [],

  setDruzhinaModal: (data) => set({ modalDruzhina: data }),
  setTaskModal: (data) => set({ modalTask: data }),
  setPersonModal: (data) => set({ modalPerson: data }),
  setGatheringPointModal: (data) => set({ modalGatheringPoint: data }),
  setReportModal: (data) => set({ modalReport: data }),
  setDate: (date) => set({ date: date }),
  setMode: (mode) => set({ mode }),
  setDruzhina: (druzhina) => set({ selectedDruzhina: druzhina }),
  setSelectedUser: (selectedUser) => set({ selectedUser }),
  setFilters: (filters) => set({ filters }),
  toggleFilter: (filter) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [filter]: !state.filters[filter],
      },
    })),

  getMilitiasRequest: async () => {
    const response = await API.get<IGetMulitiasRequest[]>({
      endPoint: "/admin/militias/",
    });

    const users = response.data.reduce((acc: IUsers[], item) => {
      const users: IUsers[] = item.participants.map((user) => ({
        label: user.user_name,
        value: user.user_id,
      }));
      acc = [...acc, ...users];
      return acc;
    }, []);

    set({ allUsers: users, druzhiny: response.data });
  },

  getNewDataRequest: async () => {
    try {
      const { selectedDruzhina, selectedUser, date } = get();
      const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null;

      const params = new URLSearchParams();
      if (selectedDruzhina !== null) params.append("militia_id", String(selectedDruzhina));
      if (selectedUser !== null) params.append("user_id", String(selectedUser));
      if (formattedDate) params.append("date", formattedDate);

      const queryString = params.toString();

      const response = await API.get<ApiResponseData>({
        endPoint: `/admin/map/?${queryString}`,
      });

      const { people = [], gathering_points = [], tasks = [], reports = [] } = response.data || {};

      set({
        newPeopleData: people || [],
        newGatheringPoints: gathering_points || [],
        newTasks: tasks || [],
        newReports: reports || [],
        currentShift: [],
      });
    } catch (error) {
      console.error("Ошибка при получении новых данных:", error);
    }
  },

  getRoutesRequest: async () => {
    try {
      const { selectedUser, date, druzhiny, selectedDruzhina } = get();

      let userIds = String(selectedUser);
      if (selectedUser === null || !date) {
        console.error("Не выбран пользователь или дата.");
        return;
      }

      if (selectedUser === "Все") {
        const findDruzhina = druzhiny.find((item) => item.id === selectedDruzhina);
        let users = findDruzhina?.participants.map((item) => item.user_id);
        if (users) {
          userIds = users?.join(",");
        }
      }

      const formattedDate = moment(date).format("YYYY-MM-DD");

      const response = await API.get<RoutesApiResponse[]>({
        endPoint: `/admin/routes/?user_ids=${userIds}&date=${formattedDate}`,
      });

      const routesArray = response.data || [];

      // Сохраняем массив объектов CurrentShiftRoute
      const currentShift: CurrentShiftRoute[] = routesArray.map((route) => ({
        shifts: route.shifts,
        user: route.user,
      }));

      const tasks = routesArray.flatMap((route) => route.tasks) || [];
      const reports = routesArray.flatMap((route) => route.reports) || [];

      set({
        currentShift, // Сохраняем данные о смене
        newTasks: tasks || [],
        newReports: reports || [],
        newPeopleData: [],
        newGatheringPoints: [],
      });
    } catch (error) {
      set({
        currentShift: [], // Обновляем данные о смене
        newTasks: [],
        newReports: [],
        newPeopleData: [],
        newGatheringPoints: [],
      });
      console.error("Ошибка при получении маршрута:", error);
    }
  },
}));

export default useGlobalStore;
