import React, { useContext, useState, useEffect } from "react";
import { TabMenu } from "primereact/tabmenu";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import useGlobalStore from "../store/useGlobalStore";

interface IItems {
  label: "Стандарт" | "Маршруты";
  icon: string;
}

export const TabMenuComponent: React.FC = () => {
  const { mode, setMode } = useGlobalStore();
  const [activeIndex, setActiveIndex] = useState(0);

  const items: IItems[] = [
    { label: "Стандарт", icon: "pi pi-fw pi-check" },
    { label: "Маршруты", icon: "pi pi-fw pi-map" },
  ];

  useEffect(() => {
    const index = items.findIndex((item) => item.label === mode);
    console.log("Обновление activeIndex:", index);
    setActiveIndex(index);
  }, [mode]);

  const handleModeChange = (index: number) => {
    const modeActive = items[index].label;
    console.log("Выбрана вкладка с индексом:", index, "и значением:", modeActive);
    setMode(modeActive);
  };

  return (
    <TabMenu
      key={activeIndex} // Добавление ключа для принудительной перерисовки
      model={items}
      activeIndex={activeIndex} // Передаем состояние activeIndex
      onTabChange={(e) => {
        console.log("Переключение вкладки на индекс:", e.index);
        handleModeChange(e.index);
      }} // Обработчик изменения вкладки
    />
  );
};
