import React, { useContext } from "react";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import useGlobalStore, { FiltersState } from "../store/useGlobalStore";

const Filters: React.FC = () => {
  const { filters, toggleFilter } = useGlobalStore();

  return (
    <div style={{ width: "300px" }}>
      <Panel header="Фильтры" className="h-48" toggleable>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="closedTasks" checked={filters.closedTasks} onChange={() => toggleFilter("closedTasks")} />
          <label className="ml-2" htmlFor="closedTasks">
            Закрытые задачи
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="openTasks" checked={filters.openTasks} onChange={() => toggleFilter("openTasks")} />
          <label className="ml-2" htmlFor="openTasks">
            Открытые задачи
          </label>
        </div>
        {/* <div className="p-field-checkbox mt-1">
          <Checkbox inputId="comanderIncidents" checked={filters.comanderIncidents} onChange={() => toggleFilter("comanderIncidents")} />
          <label className="ml-2" htmlFor="comanderIncidents">
            Происшествия командиру
          </label>
        </div> */}
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="telegramIncidents" checked={filters.telegramIncidents} onChange={() => toggleFilter("telegramIncidents")} />
          <label className="ml-2" htmlFor="telegramIncidents">
            Происшествия телеграм
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="volunteers" checked={filters.volunteers} onChange={() => toggleFilter("volunteers")} />
          <label className="ml-2" htmlFor="volunteers">
            Дружинники
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="commanders" checked={filters.commanders} onChange={() => toggleFilter("commanders")} />
          <label className="ml-2" htmlFor="commanders">
            Командиры
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="squadHeadquarters" checked={filters.squadHeadquarters} onChange={() => toggleFilter("squadHeadquarters")} />
          <label className="ml-2" htmlFor="squadHeadquarters">
            Штабы дружин
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="sosSignals" checked={filters.sosSignals} onChange={() => toggleFilter("sosSignals")} />
          <label className="ml-2" htmlFor="sosSignals">
            SOS сигналы
          </label>
        </div>
        <div className="p-field-checkbox mt-1">
          <Checkbox inputId="collectionPoints" checked={filters.collectionPoints} onChange={() => toggleFilter("collectionPoints")} />
          <label className="ml-2" htmlFor="collectionPoints">
            Точки сбора
          </label>
        </div>
      </Panel>
    </div>
  );
};

export default Filters;
