const COMMON_CONFIG = {
  REQUEST_TIMEOUT: 60000,
};

const environmentConfig = {
  development: {
    API_URL: "https://dev.24dnd.ru",
  },
  production: {
    API_URL: "https://24dnd.ru",
  },
  test: {
    API_URL: "https://dev.24dnd.ru",
  },
};

interface IConfig {
  REQUEST_TIMEOUT: number;
  API_URL: string;
}

function getApiUrl(): string {
  const isProd = process.env.NODE_ENV === "production";
  const urlParams = new URLSearchParams(window.location.search);
  const modeParam = urlParams.get("mode");

  if (isProd && modeParam === "dev") {
    return environmentConfig.development.API_URL;
  }

  if (!isProd && modeParam === "prod") {
    return environmentConfig.production.API_URL;
  }

  return isProd ? environmentConfig.production.API_URL : environmentConfig.development.API_URL;
}

const config: IConfig = {
  ...COMMON_CONFIG,
  API_URL: getApiUrl(),
};

export default config;
