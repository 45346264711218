import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Report } from "../../store/useGlobalStore";
import config from "../../constants/config";

interface IncidentModalProps {
  item: Report | null;
  onHide: () => void;
}

export const IncidentModal: React.FC<IncidentModalProps> = ({ item, onHide }) => {
  console.log("item", item);
  return (
    <Dialog header={`Происшествие Telegram`} visible={item ? true : false} onHide={onHide} style={{ width: "450px" }} modal className="p-fluid">
      <div className="p-field">
        <span>
          Дружинник:{" "}
          <a href={`${config.API_URL}/admin/users/user/${item?.user_id}/`} className="text-primary">
            {item?.user_name}
          </a>
        </span>
      </div>
      <div className="p-field mt-1.5">
        <span>Описание: {item?.description}.</span>
      </div>
      {/* <div className="p-field">
        <span>Фотографии с происшествия:</span>
        <div className="image-gallery">
          <img src="image1.jpg" alt="image1" className="gallery-image" />
          <img src="image2.jpg" alt="image2" className="gallery-image" />
          <img src="image3.jpg" alt="image3" className="gallery-image" />
        </div>
      </div> */}
    </Dialog>
  );
};
