import React from "react";
import { Calendar } from "primereact/calendar";
import useGlobalStore from "../store/useGlobalStore";
import { addLocale } from "primereact/api"; // Импортируем метод для добавления локализаций

addLocale("ru", {
  firstDayOfWeek: 1,
  dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
  dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
  monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  today: "Сегодня",
  clear: "Очистить",
  dateFormat: "dd.mm.yy", // Задаем формат даты: день.месяц.год
  weekHeader: "Нед",
});

export const DatePickerComponent: React.FC = () => {
  const { date, setDate } = useGlobalStore();

  return (
    <div className="relative">
      <Calendar
        value={date} // Ожидаем Date или null
        onChange={(date) => {
          if (date.target.value) {
            setDate(date.target.value);
          } else {
            setDate(null); // Если пустое поле, устанавливаем дату null
          }
        }} // Передаем обработчик события
        inputClassName=""
        placeholder="Выберите дату"
        showIcon
        readOnlyInput
        selectionMode="single" // Указываем режим выбора одной даты
        locale="ru"
      />
    </div>
  );
};
