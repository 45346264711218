import React from "react";
import { Dialog } from "primereact/dialog";
import { Task } from "../../store/useGlobalStore";
import config from "../../constants/config";

interface TaskModalProps {
  item: Task | null;
  onHide: () => void;
}

export const TaskModal: React.FC<TaskModalProps> = ({ item, onHide }) => {
  return (
    <Dialog header={`Задача: "${item?.name}"`} visible={item ? true : false} onHide={onHide} style={{ width: "450px" }} modal className="p-fluid">
      <div className="p-field m-1.5">
        <span>
          На кого поставлена задача:{" "}
          <a href={`${config.API_URL}/admin/users/user/${item?.executor}/`} target="_blank" className="text-primary">
            {item?.executor_name}
          </a>
        </span>
      </div>
      <div className="p-field m-1.5">
        <span>
          Кем поставлена задача:{" "}
          <a href={`${config.API_URL}/admin/users/user/${item?.creator}/`} target="_blank" className="text-primary">
            {item?.creator_name}
          </a>
        </span>
      </div>
      <div className="p-field m-1.5">
        Описание: <span className="text-primary">{item?.description}</span>
      </div>
      <div className="p-field m-1.5">
        <span>Статус: {item?.status === "completed" ? <span className="text-green-500">Закрыта</span> : <span className="text-danger">Не закрыта</span>}</span>
      </div>
    </Dialog>
  );
};
