import axios from "axios";
import { IHttpClientMainMethodParams, IHttpClientRequestParams, IHttpClientResponse } from "./httpClient.models";
import config from "../constants/config";

const instanceAxios = axios.create({
  validateStatus: function () {
    return true;
  },
});

class HttpClientApi {
  public post<TResponse, TBody>(httpClientParams: IHttpClientRequestParams<TBody>) {
    return this.main<TResponse, TBody>({
      httpClientMethod: "POST",
      httpClientParams,
    });
  }

  public get<TResponse>(httpClientParams: Omit<IHttpClientRequestParams<null>, "body">) {
    return this.main<TResponse, null>({
      httpClientMethod: "GET",
      httpClientParams,
    });
  }

  public put<TResponse, TBody>(httpClientParams: IHttpClientRequestParams<TBody>) {
    return this.main<TResponse, TBody>({
      httpClientMethod: "PUT",
      httpClientParams,
    });
  }

  public patch<TResponse, TBody>(httpClientParams: IHttpClientRequestParams<TBody>) {
    return this.main<TResponse, TBody>({
      httpClientMethod: "PATCH",
      httpClientParams,
    });
  }

  public delete<TResponse, TBody>(httpClientParams: IHttpClientRequestParams<TBody>) {
    return this.main<TResponse, TBody>({
      httpClientMethod: "DELETE",
      httpClientParams,
    });
  }

  private async main<TResponse, TBody>(mainParams: IHttpClientMainMethodParams<TBody>, retry = true): Promise<IHttpClientResponse<TResponse>> {
    if (!mainParams.httpClientParams.responseType) {
      mainParams.httpClientParams.responseType = "json";
    }

    try {
      const response = await instanceAxios({
        method: mainParams.httpClientMethod,
        data: mainParams.httpClientParams.body,
        withCredentials: true,
        headers: {
          ...mainParams.httpClientParams.headers,
        },
        responseType: mainParams.httpClientParams.responseType,
        url: mainParams.httpClientParams.url
          ? `${mainParams.httpClientParams.url}${mainParams.httpClientParams.endPoint}`
          : `${config.API_URL}/api/v1${mainParams.httpClientParams.endPoint}`,
        timeout: config.REQUEST_TIMEOUT,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export const API = new HttpClientApi();
