import React, { useState, ReactNode, useEffect, useRef } from "react";
import { Marker, Popup } from "react-map-gl";
import SquadMarkerSVG from "../../assets/markers/SquadMarker.svg";
import UserMarkerSVG from "../../assets/markers/UserMarker.svg"; // Добавляем новый маркер
import { Button } from "primereact/button";
import { GatheringPoint, Person, Task, Report } from "../../store/useGlobalStore";
import GatheringPointsSVG from "../../assets/markers/GatheringPoints.svg";
import TaskActive from "../../assets/markers/TaskActive.svg";
import TaskUnActive from "../../assets/markers/TaskUnActive.svg";
import ReportTelegramMarkerSVG from "../../assets/markers/IncidentTelegram.svg"; // Новый маркер для репорта
import moment from "moment";
// Определение возможных значений типа через строковые литералы
type MarkerType = "Squad" | "Task" | "User" | "Gathering_Point" | "Report"; // Добавлен новый тип "Report"

// Интерфейсы для кастомных данных каждого типа
interface GatheringPointData {
  point: GatheringPoint;
}

interface SquadData {
  name: string;
  squadSize: number;
}

interface TaskData {
  task: Task;
}

interface UserData {
  user: Person;
}

interface ReportData {
  report: Report;
}

// Интерфейс для всех типов данных
type MarkerData = SquadData | TaskData | UserData | GatheringPointData | ReportData; // Добавлен тип ReportData

interface SquadMarkerProps<T extends MarkerData> {
  latitude: number;
  longitude: number;
  type: MarkerType;
  data: T; // Данные будут зависеть от типа маркера
  onClick: () => void;
}

// Функция для получения изображения маркера по типу и данным
const getMarkerImage = (type: MarkerType, data: MarkerData): React.ReactNode => {
  switch (type) {
    case "Squad":
      return <img src={SquadMarkerSVG} alt="marker" style={{ cursor: "pointer", width: "30px", height: "30px" }} />;
    case "Gathering_Point":
      return <img src={GatheringPointsSVG} alt="marker" style={{ cursor: "pointer", width: "30px", height: "30px" }} />;
    case "Task": {
      const taskData = data as TaskData;
      if (taskData.task.status === "completed") {
        return <img src={TaskUnActive} alt="marker" style={{ cursor: "pointer", width: "30px", height: "30px" }} />;
      }
      return <img src={TaskActive} alt="marker" style={{ cursor: "pointer", width: "30px", height: "30px" }} />;
    }
    case "User": {
      const userData = data as UserData;

      // Check if the user has an active SOS signal
      if (userData.user.active_sos_signal && userData.user.active_sos_signal.is_active) {
        return (
          <div style={{ position: "relative", display: "inline-block" }}>
            {/* SOS flag positioned above the avatar */}
            <div
              style={{
                position: "absolute",
                top: "-30px", // Adjust this value to position the flag
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#F44336", // SOS red background color
                color: "white",
                padding: "2px 8px",
                borderRadius: "12px",
                fontSize: "12px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Optional: adds a shadow to the flag
              }}
            >
              SOS {moment(userData.user.active_sos_signal.created_at).format("HH:MM")}
            </div>

            {/* User avatar marker */}
            <img
              src={userData.user.avatar ? userData.user.avatar : require("../../assets/markers/avatar.png")}
              alt={userData.user.full_name}
              className="cursor-pointer w-8 h-8 rounded-full border-[-2px] border-red-500 border-solid z-10"
            />
          </div>
        );
      }

      // If there's no active SOS signal, just display the regular avatar
      return (
        <img
          src={userData.user.avatar ? userData.user.avatar : require("../../assets/markers/avatar.png")}
          alt={userData.user.full_name}
          className="cursor-pointer w-8 h-8 rounded-full border-[-2px] border-blue-600 border-solid"
        />
      );
    }

    case "Report": {
      // Новый случай для репортов
      return <img src={ReportTelegramMarkerSVG} alt="report-marker" style={{ cursor: "pointer", width: "30px", height: "30px" }} />;
    }
    default:
      return null; // Возвращаем null, если тип не распознан
  }
};

// Функция для получения HTML-контента попапа по типу
const getPopupContent = (type: MarkerType, data: MarkerData): ReactNode => {
  switch (type) {
    case "Squad": {
      const squadData = data as SquadData;
      return (
        <div>
          <h3 className="text-primary font-semibold m-0">Дружина</h3>
          <p className="text-primary font-medium m-0 -mt-2">{squadData.name}</p>
          <p className="text-muted m-0">Численность: {squadData.squadSize}</p>
        </div>
      );
    }
    case "Gathering_Point": {
      const gatheringData = data as GatheringPointData;
      return (
        <div>
          <h3 className="text-primary font-semibold m-0">Точка сбора</h3>
        </div>
      );
    }
    case "Task": {
      const taskData = data as TaskData;
      return (
        <div>
          <h3 className="text-danger font-semibold m-0">Задача</h3>
          <p className="text-muted font-medium m-0 -mt-2">{taskData.task.name}</p>
        </div>
      );
    }
    case "User": {
      const userData = data as UserData;
      return (
        <div>
          <h3 className="text-info font-semibold m-0">Пользователь</h3>
          <p className="text-primary font-medium m-0 -mt-2">Имя: {userData.user.full_name}</p>
        </div>
      );
    }
    case "Report": {
      // Новый случай для репортов
      const reportData = data as ReportData;
      return (
        <div>
          <h3 className="text-warning font-semibold m-0">Происшествие</h3>
        </div>
      );
    }
    default:
      return (
        <div>
          <h3 className="text-warning font-semibold m-0">Неизвестный тип</h3>
        </div>
      );
  }
};

export const MarkerComponent = <T extends MarkerData>({ latitude, longitude, type, data, onClick }: SquadMarkerProps<T>) => {
  const [showPopup, setShowPopup] = useState(false);

  const markerImage = getMarkerImage(type, data); // Передаем и тип, и данные
  const popupContent = getPopupContent(type, data);

  const popupRef = useRef<HTMLDivElement>(null);

  // Закрываем попап при клике вне попапа
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Ищем есть ли попап в DOM
      const popupElement = document.querySelector(".mapboxgl-popup-content");
      if (popupElement && !popupElement.contains(event.target as Node)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      // Добавляем слушатель только при открытом попапе
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Удаляем слушатель при закрытии попапа
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <>
      <Marker latitude={latitude} longitude={longitude} onClick={() => setShowPopup(!showPopup)}>
        {markerImage ? markerImage : null}
      </Marker>

      {showPopup && (
        <Popup latitude={latitude} longitude={longitude} offset={20} onClose={() => setShowPopup(false)} closeOnClick={false}>
          <div className="m-5">
            <div className="text-center">{popupContent}</div>
            <div className="mt-1 text-center">
              <Button onClick={() => onClick()} size="small">
                Подробнее
              </Button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
